(function () {
  'use strict';

  angular
    .module('wbAccount.wbCompany')
    .config(config);

  function config($stateProvider, authenticatedOnly) {
    $stateProvider
      // .state('csnFileDownload', {
      //   url: '/csn-file-download?page',
      //   templateUrl: 'wb-account/wb-company/views/csn-file-download.tpl.html',
      //   controller: 'CsnFileDownloadCtrl',
      //   controllerAs: 'csnFileDownload',
      //   restrict: authenticatedOnly,
      //   resolve: {
      //     csnFiles: function (LoggedUser, Company) {
      //       var company = LoggedUser.getCompany();

      //       return Company.getCsnFiles({companyId: company.id}).$promise;
      //     }
      //   }
      // })
      .state('completeCsnLists', {
        url: '/complete-csn-lists',
        templateUrl: 'wb-account/wb-company/views/complete-csn-lists.tpl.html',
        controller: 'CompleteCsnListsCtrl',
        controllerAs: 'completeCsnLists',
        restrict: authenticatedOnly
      });
  }
}());
